export default {
    hasPermission: (state) => (req) => {
        if (state.accountInfo.permission && state.accountInfo.permission.length > 0) {
            if (state.accountInfo.permission.find(p => p.function == req.function
                    && p.operation.indexOf(req.operation) > -1)) {
                // if (req.function=="Slide" && req.operation == "update") {
                // console.log(2, state.accountInfo.permission.find(p => p.function == req.function
                //     && p.operation.indexOf(req.operation) > -1));
                // }
                return true;

            }
        }
        return false;
    },
    hasUserAllDataReadPermission: (state) => (req) => {
        if (state.accountInfo.role != 5) {
            //クライアント管理者（営業）がゆーざーID・性別・卒業年度を見える
            return true;
        }
        return false;
    },
    getImageTips:  (state) => (req) => {
        return "・容量：２MBまで<br>" +
            "・サイズ：" + req.x + "pix ✕ " + req.y + "pix<br>" +
            "※容量によっては設定後、反映までに時間がかかる場合があります。";
    }
}
