import Vue from "vue";
import VueRouter from "vue-router";
import { ROUTER_PATH } from "@/constants/common.js";

let originPush =  VueRouter.prototype.push;  //备份原push方法

VueRouter.prototype.push = function (location, resolve, reject){
  if (resolve && reject) {    //如果传了回调函数，直接使用
    originPush.call(this, location, resolve, reject);
  }else {                     //如果没有传回调函数，手动添加
    originPush.call(this, location, ()=>{}, ()=>{});
  }
}

Vue.use(VueRouter);

const routes = [
  {
    path: ROUTER_PATH.LOGIN,
    name: "login",
    component: () => import("../views/account/Login.vue"),
    meta: { title: "ログイン", needLogin: false }
  },

  {
    path: ROUTER_PATH.MODIFY,
    name: "modify",
    component: () => import("../views/account/modify"),
    meta: { title: "パスワード再発行", needLogin: false }
  },

  {
    path: ROUTER_PATH.INDEX,
    name: "index",
    component: () => import("../views/index"),
    meta: { title: "ログイン", needLogin: true },
    children: [
      {
        path: ROUTER_PATH.INDEX_2,
        name: "index-2",
        component: () => import("../components/content/index"),
        meta: { title: "ダッシュボード", needLogin: true }
      },
      {
        path: ROUTER_PATH.INDEX,
        name: "index-3",
        component: () => import("../components/content/index"),
        meta: { title: "ダッシュボード", needLogin: true }
      },
      {
        path: ROUTER_PATH.PROCESS_DESIGN,
        name: "processDesign",
        component: () => import("../components/content/processDesign"),
        meta: { title: "フロー設計", needLogin: true }
      },

      {
        path: ROUTER_PATH.PROCESS_OPERATION,
        name: "processOperation",
        component: () => import("../components/content/processOperation"),
        meta: { title: "フロー運用", needLogin: true }
      },
      {
        path: ROUTER_PATH.EXPLAIN_MEETING,
        name: "explainMeeting",
        component: () => import("../components/content/explainMeeting"),
        meta: { title: "説明会管理", needLogin: true }
      },
      {
        path: ROUTER_PATH.EXPLAIN_MEETINGADD,
        name: "explainMeetingAdd",
        component: () => import("../components/content/explainMeetingAdd"),
        meta: { title: "説明会管理", needLogin: true }
      },
      {
        path: ROUTER_PATH.ENTRY_LEVEL,
        name: "entryLevel",
        component: () => import("../components/content/entryLevel"),
        meta: { title: "エントリー管理", needLogin: true }
      },
      {
        path: ROUTER_PATH.ENTRY_LEVEL_DETAIL,
        name: "entryLevelDetail",
        component: () => import("../components/pageComponents/entryLevelDetail"),
        meta: { title: "エントリー管理", needLogin: true }
      },
      {
        path: ROUTER_PATH.SPECIAL_ENTRY,
        name: "specialEntry",
        component: () => import("../components/content/specialEntry"),
        meta: { title: "特別エントリー管理", needLogin: true }
      },
      {
        path: ROUTER_PATH.SPECIAL_ENTRY_DETAIL,
        name: "specialEntryDetail",
        component: () => import("../components/pageComponents/specialEntryDetail"),
        meta: { title: "特別エントリー管理", needLogin: true }
      },
      {
        path: ROUTER_PATH.PERSONA,
        name: "persona",
        component: () => import("../components/content/persona"),
        meta: { title: "ペルソナ", needLogin: true }
      },
      {
        path: ROUTER_PATH.DELIVERY_SETTINGS,
        name: "deliverySettings",
        component: () => import("../components/content/deliverySettings"),
        meta: { title: "送信設定", needLogin: true }
      },

      {
        path: ROUTER_PATH.SCOUT,
        name: "scout",
        component: () => import("../components/content/scout"),
        meta: { title: "スカウト", needLogin: true }
      },
      {
        path: ROUTER_PATH.SAVE_LIST,
        name: "saveList",
        component: () => import("../components/content/saveList"),
        meta: { title: "保存リスト", needLogin: true }
      },
      {
        path: ROUTER_PATH.STU_KNOW,
        name: "stuKnow",
        component: () => import("../components/content/stuKnow"),
        meta: { title: "学生へのお知らせ", needLogin: true }
      },
      {
        path: ROUTER_PATH.ACCOUNT,
        name: "account",
        component: () => import("../components/content/account"),
        meta: { title: "アカウント", needLogin: true }
      },
      {
        path: ROUTER_PATH.ACCOUNT_FORM,
        name: "accountForm",
        component: () => import("../components/content/accountForm"),
        meta: { title: "アカウント", needLogin: true }
      },
      {
        path: ROUTER_PATH.HELP,
        name: "help",
        component: () => import("../components/content/help"),
        meta: { title: "ヘルプ", needLogin: true }
      },
      {
        path: ROUTER_PATH.AE,
        name: "help",
        component: () => import("../components/content/ae"),
        meta: { title: "ヘルプ", needLogin: true }
      },
    ]
  },




  // {
  //   path: "/",
  //   name: "layout",
  //   component: () => import("../views/layout"),
  //   redirect: ROUTER_PATH.LOGIN,
  //   //redirect:ROUTER_PATH.DASHBOARD_LIST,
  //   children: [
  //     {
  //       path: ROUTER_PATH.SLIDE_LIST,
  //       name: "slideList",
  //       component: () => import("../views/pprh/SlideList.vue"),
  //       meta: { title: "TOPスライダー管理", needLogin: true }
  //     },
  //     {
  //       path: ROUTER_PATH.UPDATE_MASTER,
  //       name: "updateMaster",
  //       component: () => import("../views/master/UpdateMaster.vue"),
  //       meta: { title: "マスター更新", needLogin: true }
  //     },
  //     // {
  //     //   path: ROUTER_PATH.SYS_CONFIG,
  //     //   name: "sysConfig",
  //     //   component: () => import("../views/config/SysConfig.vue"),
  //     //   meta: { title: "システム設定", needLogin: true }
  //     // },
  //   ]
  // },
  // {
  //   path: "/error",
  //   name: "errorPage",
  //   component: () => import("../views/layout"),
  //   children: [
  //     {
  //       path: "/404",
  //       name: "Page404",
  //       component: () => import("../views/errorPage/404.vue"),
  //       meta: { title: "Page 404" }
  //     },
  //     {
  //       path: "/401",
  //       name: "Page401",
  //       component: () => import("../views/errorPage/401.vue"),
  //       meta: { title: "Page 401" }
  //     }
  //   ]
  // },
  // {
  //   path: "*",
  //   redirect: "/404"
  // }
];

const router = new VueRouter({
  routes
});

export default router;
