module.exports = {
    "common":{
        "popup":"メッセージ",
        "ok":"OK",
        "cancel":"取消"
    },
    "login":{
        "username":"アカウント",
        "password":"パスワード",
        "slogan":"就職活動は、給与や仕事内容、勤務地など「どこで何をするのか」を軸に行うことがスタンダードとなっています。<br>" +
            "私たちの目指す社長就活とは、<span class=\"blue\">社長の想い(=企業理念)やビジョンに共感</span>することを“きっかけ”とした就職活動を行うことです。<br>" +
            "そのために、企業の社長自らが<span class=\"blue\">Wi</span>ll <span class=\"blue\">n</span>ot <span class=\"blue\">C</span>hange <span class=\"blue\">“変わらない”</span> 想いを発信することが大切だと感じ誕生したサービスです。<br>" +
            "私たちはWinCを通じて、「共に会社を作っていきたい人材」と出会える場を企業へ提供し「理念共感型採用」を就活活動のスタンダードに変えていきます。<br>",
        "login":"ログイン",
        "forgetPwd":"パスワードレセット",
        "err1":"ユーザー名を入力してください。",
        "err2":"パスワードを入力してください。"
    },
    "account":{
        "account":"アカウント",
        "info":"アカウント情報",
        "contactor":"名前",
        "mobile":"携帯",
        "mail1":"メール",
        "systemmail":"システム通知",
        "billmail":"請求書",
        "update":"更新",
        "cancel":"取消",
        "err1":"名前を入力してください。",
        "err2":"1桁から20桁の内容を入力してください。",
        "err3":"携帯番号を入力してください。",
        "err4":"11桁の携帯番号を入力してください。",
        "err5":"メールアドレスを入力してください。",
        "err6":"正しいメールアドレスを入力してください。"
    },
    "forgetPWD":{
        "mail":"メール",
        "reset":"パスワードリセット",
        "back":"戻る",
        "err1":"メールアドレスを入力してください。",
        "suc1":"パスワードリセットは成功しました。結果についてメールで確認してください。"
    },
    "resetPWD":{
        "account":"アカウント",
        "udpatePWD":"パスワード更新",
        "oldPassword":"パスワード",
        "newPassword":"新パスワード",
        "newPassword2":"確認パスワード",
        "update":"更新",
        "err1":"パスワードが一致していません。",
        "err2":"パスワードを入力してください。",
        "err3":"8桁から20桁の内容を入力してください。",
        "err4":"新パスワードを入力してください。",
        "err5":"確認パスワードを入力してください。"
    },
    "addGroup":{
        "admin":"管理",
        "addRole":"グループ新規",
        "role":"グループ情報",
        "name":"グループ名",
        "process":"プロセス",
        "add":"新規",
        "back":"戻る"
    },
    "addUser":{
        "admin":"管理",
        "addUser":"ユーザー新規",
        "account":"ユーザー情報",
        "loginName":"アカウント",
        "name":"ユーザー名",
        "contactor":"ユーザー名",
        "password":"パスワード",
        "A":"システム管理員",
        "B":"普通社員",
        "C":"代理店社員",
        "T":"技術",
        "role":"ロール",
        "group":"グループ",
        "mobile":"携帯",
        "mail1":"メール",
        "systemmail":"システム通知",
        "billmail":"請求書",
        "notice":"通知",
        "notnotice":"通知しない",
        "create":"保存",
        "back":"戻る",
        "err1":"ユーザー名を入力してください。",
        "err2":"1桁から20桁の内容を入力してください。",
        "err3":"携帯番号を入力してください。",
        "err4":"11桁の携帯番号を入力してください。",
        "err5":"メールを入力してください。",
        "err6":"正しいメールアドレスを入力してください。",
        "err7":"パスワードを入力してください。",
        "err8":"正しいパスワードを入力してください。"
    },
    "listGroup":{
        "group":"件",
        "add":"新規",
        "code":"コード",
        "name":"グループ名",
        "bindprocess":"プロセス",
        "process":"処理",
        "remove":"削除",
        "confirm":"該当ロールを削除してよろしいでしょうか？",
        "popup":"メッセージ",
        "ok":"OK",
        "cancel":"取消"
    },
    "listUser":{
        "user":"件",
        "add":"新規",
        "loginName":"アカウント",
        "name":"ユーザー名",
        "role":"ロール",
        "group":"グループ",
        "process":"処理",
        "edit":"修正",
        "remove":"削除",
        "resetPwd":"該当ユーザーのパスワードを再発行します。よろしいでしょうか？",
        "confirm":"該当ユーザーを削除してよろしいでしょうか？",
        "popup":"メッセージ",
        "ok":"OK",
        "cancel":"取消"
    },
    "updateUser":{
        "manage":"管理",
        "updateUser":"ユーザー情報更新",
        "update":"更新",
        "err1":"ユーザー名を入力してください。",
        "err2":"1桁から20桁の内容を入力してください。",
        "err3":"携帯番号を入力してください。",
        "err4":"11桁の携帯番号を入力してください。",
        "err5":"メールを入力してください。",
        "err6":"正しいメールアドレスを入力してください。",
        "err7":"パスワードを入力してください。",
        "err8":"正しいパスワードを入力してください。"
    },
    "payReport":{
        "totalCount":"件",
        "month":"月",
        "functionName":"機能",
        "currentPlan":"セット",
        "extraFee":"セット外費用",
        "totalFee":"アカウント変更",
        "balance":"アカウント残額",
        "date":"処理時間"
    },
    "processReview":{
        "auto":"自動",
        "manual":"手動",
        "command":"実行",
        "resource":"リソース",
        "back":"返回"
    },
    "process":{
        "my":"マイプロセス",
        "start":"起動...",
        "stop":"終了...",
        "confirm1a":"プロセス",
        "confirm1b":"をオンラインにしてよろしいでしょうか。",
        "confirm2a":"プロセス",
        "confirm2b":"をオフラインにしてよろしいでしょうか。"
    },
    "monitor":{
        "input":"ファイル",
        "robotName":"ロボット",
        "functionName":"機能",
        "date":"日付",
        "start":"開始",
        "end":"終了",
        "result":"結果",
        "process":"処理",
        "redo":"再起動",
        "unit":"件"
    },
    "myrobot":{
        "confirm1a":"ロボット",
        "confirm1b":"を起動してよろしいでしょうか",
        "confirm2a":"ロボット",
        "confirm2b":"を停止してよろしいでしょうか",
        "confirm3a":"ロボット",
        "confirm3b":"を更新してよろしいでしょうか",
        "start":"起動...",
        "stop":"終了...",
    },
    "runningReport":{
        "graph":"グラフ",
        "calling":"実行回数",
        "durationAvg":"平均実行時間（秒）",
        "persent":"成功率",
        "newMonth":"期間",
        "month":"対象月",
        "date":"日付",
        "robotName":"ロボット",
        "functionName":"機能",
        "callSum":"実行回数",
        "callSuccess":"成功回数",
        "callFail":"失敗回数",
        "duration":"実行時間（秒）",
        "list":"一覧",
        "unit":"件"
    },
    "planList":{
        "set":"セット情報",
        "function":"機能",
        "plan":"名前",
        "start":"有効期間開始",
        "end":"有効期間終了",
        "amount":"残回数",
        "balance":"残額"
    },
    "menu":{
        "process":"プロセス",
        "robot":"ロボット",
        "cloud":"クラウド",
        "report":"レポート",
        "bill":"費用",
        "admin":"管理",
        "user":"ユーザー",
        "group":"グループ",
        "account":"アカウント",
        "password":"パスワード修正",
        "logout":"ログアウト"
    },
    "processItem":{
        "online":"オンライン",
        "offline":"オフライン",
        "workday":"稼働日",
        "day":"毎日",
        "detail":"詳細",
        "ver":"版"
    },
    "robotItem":{
        "online":"起動",
        "offline":"終了",
        "config":"設定",
        "update":"更新",
        "ver":"版"
    },
    "robotConfig":{
        "config":"-設定",
        "exe":"実行方法：",
        "by":"頻度",
        "bytime":"定時",
        "time":"間隔時間：",
        "unit":"単位",
        "min":"分",
        "hour":"時間",
        "day":"毎日：",
        "selecttime":"対象時間",
        "weekend":"週末込み",
        "hint":"再起動してください。"
    },
    "report":{
        "function":"機能",
        "byfunction":"機能実行回数集計",
        "byrobot":"ロボット実行回数集計",
        "bymonth":"当月実行回数集計",
    },
    "search":{
        "search":"検索",
        "reset":"リセット",
        "selMonth":"月",
        "selDuration":"期間",
        "selFunction":"機能",
        "today":"当日",
        "week":"当週",
        "month":"当月",
        "selStatus":"状態",
        "success":"成功",
        "fail":"失敗",
        "running":"実行中",
        "selRobot":"ロボット",
        "file":"ファイル",
        //TASK
        "created":"アサインなし",
        "signed":"アサイン済",
        "saved":"保存",
        "completed":"完成",
        "deleted":"削除",
        "keyword":"キーワード",
        "out":"出荷フォーム",
        "compare":"比較",
        "customer":"税関申込",
        "OrgPic": "原図"
    },
    "task": {
        "view": "見る",
        "type":"種別",
        "keyword":"キーワード",
        "createtime":"新規時間",
        "assigntime":"アサイン時間",
        "endtime": "完了時間",
        "interval": "間隔",
        "assigner":"アサイン者",
        "status":"状態",
        "process":"操作",
        "assign":"アサイン",
        "delete":"削除",
        "truncate":"クリア",
        "rollback":"回復",
        "file":"ファイル",
        "robot":"ロボット",
        "date":"日付",
        "start":"開始時間",
        "end":"終了時間",
        "info":"メッセージ",
        "compare":"比較",
        "redo":"再実行"
    },
    "taskDetail": {
        "upload": "アップロード", 
        "repeatCheck": "重複チェック", 
        "print": "印刷",
        "save":"保存",
        "submit":"サブミット",
        "delete":"削除",
        "back":"戻る",
        "addRow":"データ追加",
        "openOrgPic":"原図",
        "recSeal":"印章識別",
        "storeCmt":"倉庫備考",
        "autoPick":"自動ピック"
    },
    "listCustomer":{
        "count":"件",
        "add":"新規",
        "nameKj":"顧客名（漢）",
        "applyDate":"申込日",
        "printKbn":"印刷区分",
        "newDay":"新着曜日",
        "occupation":"職種",
        "visitDate":"訪問日時",
        "status":"処理ステータス",
        "createUser":"登録ユーザー",
        "createTime":"登録時間",
        "ok":"OK",
        "cancel":"取消",
        "process":"処理",
    },
    "addCustomer":{
        "title": "案件登録",
        "title2": "案件修正",
        "registry": "登記簿情報",
        "corporateName": "法人名",
        "representative": "代表者名",
        "customer": "顧客",
        "customerId": "顧客ID",
        "search": "検索",
        "add": "新規",
        "delete": "削除",
        "confirm": "確認",
        "back": "キャンセル",
        "locationName": "拠点名",
        "name": "顧客名",
        "comp": "COMP",
        "zipCode": "郵便番号",
        "prefecture": "都道府県",
        "address1": "住所1",
        "address2": "住所2",
        "tel": "TEL",
        "fax": "FAX",
        "person1": "担当者1",
        "person2": "担当者2",
        "post": "役職",
        "comment": "コメント",
        "managementId":"管理画面ID",
        "managementPw": "管理画面PWD",
        "managementName": "管理画面名",
        "questionnaire": "アンケート",
        "destinationAddress": "メアド",
        "send": "送信する",
        "memo": "備考",
        "payee": "請求先",
        "payeeId": "請求先ID",
        "sameInformation": "顧客情報と同様",
        "payeeNm": "請求先名",
        "deAddress": "送付先住所",
        "buildName": "ビル名",
        "address3": "お宛名",
        "transferFee": "振込手数料",
        "payment": "支払方法",
        "transferName": "振込名",
        "contract": "契約内容",
        "applyDay": "申込日",
        "printKbn": "印刷区分",
        "newDay": "新着曜日",
        "job": "職種",
        "visitTime": "訪問日時",
        "section1": "部署1",
        "section2": "部署2",
        "business": "営業担当者",
        "profitSharing": "利益分配率",
        "service": "サービス",
        "recruiting": "募集先情報",
        "requiredInfo": "申込時必要情報",
        "saas": "SAAS",
        "cloudSign": "クラウドサイン情報",
        "dipSysInfo": "DIPフロントシステム情報",
    },

    "slidePages":{
        "slideId":"スライドID",
        "type":"タイプ",
        "imageUrl":"画像",
        "displayOrder":"表示順",
        "publicReservationDateTime":"公開予約日時",
        "linkUrl":"リンクURL",
        "openWindowFlag":"ページ開くタイプ",
        "alt":"Alt",
        "slideMemo":"スライダーメモ",
        "status":"状態",
        "add":"新規",
        "save":"保存",
        "back":"戻る",
        "dirName":"スライド管理",
        "place":"箇所"
    },
    "videoPages":{
        "videoId":"動画ID",
        "compName":"企業名",
        "videoTitle":"動画タイトル",

        "type":"種類",
        "display_order":"表示順",
        "public_reservation_date":"公開予約日付",
        "public_reservation_time":"公開予約時間",
        "link_url":"リンクURL",
        "alt":"Alt",
        "video_memo":"動画メモ",
        "status":"状態",
        "add":"新規",
        "save":"保存",
        "back":"戻る",
        "dir_name":"動画管理",
    },
    "newsPages":{
        "news_id":"ニュースID",
        "news_title":"タイトル",
        "contents":"本文",
        "public_reservation_datetime":"公開予約日時",
        "imageURL":"画像登録",
        "link_url":"リンクURL",
        "news_type":"ニュースタイプ",
        "news_memo":"ニュースメモ",
        "status":"状態",
        "add":"新規",
        "save":"保存",
        "back":"戻る",
        "dir_name":"ニュース管理",
    },
    "sendNewsPages":{
        "send_news_id":"ニュース配信ID",
        "large_category":"大カテゴリ",
        "small_category":"小カテゴリ",
        "detail_category":"詳細カテゴリー",
        "delivery_reservation_datetime":"配信予約日時",
        "delivery_reservation_date":"配信予約日付",
        "delivery_reservation_time":"配信予約時間",
        "delivery_title":"配信タイトル",
        "delivery_details":"配信詳細",
        "imageURL":"画像登録",
        "link_url":"リンクURL",
        "add":"新規",
        "save":"保存",
        "back":"戻る",
        "dir_name":"ニュース配信管理",
    },
    "tagPages":{
        "tagCategoryId":"タグ分類ID",
        "tagCategoryName":"タグ分類名",
        "status":"状態",
        "add":"新規",
        "save":"保存",
        "back":"戻る",
        "detail":"明細設定",
        "dirName":"タグ管理",
    },
    "tagDetailPages":{
        "tagDetailId":"タグ詳細ID",
        "tagCategoryName":"タグ分類名",
        "tagDetailName":"タグ詳細名",
        "displayOrder":"表示順",
        "status":"状態",
        "add":"新規",
        "save":"保存",
        "back":"戻る",
        "dirName":"タグ詳細管理",
    },
    "eventPages":{
        "eventId":"イベントID",
        "eventName":"イベント名",
        "eventDateTimeDuration":"開催日時",
        "appStartTime":"受付開始時間",
        "imageUrl":"画像",
        "target":"対象",
        "linkUrl":"リンク先URL",
        "type":"タイプ",
        "eventMemo":"イベントメモ",
        "displayOrder":"表示順",
        "publicReservationDateTime":"公開予約日時",
        "status":"状態",
        "add":"新規",
        "save":"保存",
        "back":"戻る",
        "dirName":"イベント管理",
        "areaPlaceId":"開催場所",
        "areaPlaceDetail":"開催場所詳細",
    },
    "eventCompanyPages":{
        "event_company_id":"イベント企業ID",
        "comp_name":"企業名",
        "display_order":"表示順",
        "add":"新規",
        "save":"保存",
        "back":"戻る",
        "dir_name":"イベント企業管理",
    },
    "eventAreaPlacePages":{
        "event_area_palce_id":"イベント地域場所ID",
        "prefecture":"都道府県",
        "place":"場所",
        "online_flag":"オンラインフラグ",
        "display_order":"表示順",
        "add":"新規",
        "save":"保存",
        "back":"戻る",
        "dir_name":"イベント地域場所管理",
    },
    "compSignupPages":{
        "id":"企業ID",
        "compName":"企業名",
        "presidentName":"代表者名",
        "lastName":"代表者氏名（姓）",
        "firstName":"代表者氏名（名）",
        "lastNameRoma":"代表者姓（ローマ字）",
        "firstNameRoma":"代表者名（ローマ字）",
        "catchCopy":"キャッチコピー",
        "position":"役職",
        "presidentHistory":"社長歴",
        "firstIndustry":"出⾝業界",
        "industry":"業界",
        "jobTitle":"職種",
        "character":"社長特徴",
        "presidentImageURL":"社長画像",
        "listImageURL":"一覧画像",
        "detailImageURL":"企業詳細ページ画像",
        "raParticipatingCompFlag":"RA参加企業フラグ",
        "publicReservationDatetime":"公開予約日時",
        "status":"状態",
        "videoName":"動画名",
        "videoUrl":"動画URL",
        "add":"新規",
        "previewURL":"プレビューURL",
        "save":"保存",
        "back":"戻る",
        "dirName":"企業登録",
    },
    "compMailAddressPages":{
        "id":"エントリーメールアドレスID",
        "compName":"企業名",
        "entryMailAddress":"エントリーメールアドレス",
        "entryMailAddressType":"エントリーメールアドレスフラグ",
        "add":"新規",
        "save":"保存",
        "back":"戻る",
        "dirName":"エントリーメールアドレス登録",
    },
    "compEntryUserPages":{
        "id":"エントリーID",
        "compName":"企業名",
        "entryUserTotalCount":"エントリーユーザー総数",
        "meetPresidentEntryTotalCount":"社⻑に会えるエントリーユーザー総数",
        "entryUserCount":"エントリーユーザー数",
        "meetPresidentEntryCount":"社⻑に会えるエントリーユーザー数",
        "yearMonth":"年月",
        "userId": 'ユーザーID',
        "userName": 'ユーザー氏名',
        "entryDateTime": 'エントリー日時',
        "entryTitle": 'エントリータイトル',
        "meetPresidentEntryTitle": '社長に会えるエントリータイトル',
        "entryType": 'エントリータイプ',
        "detail":"詳細",
        "back":"戻る",
        "dirName":"エントリーユーザー数",
    },
    "logPages":{
        "operatorId":"操作者ID",
        "operationDatetime":"操作日時",
        "openedPage":"操作画面",
        "clickedButton":"操作ボタン",
        "updatedTable":"操作テーブル",
    },
    "maintenanceCompPages":{
        "id":"企業会員ID",
        "compName":"企業名",
        "compUserName":"企業会員名",
        "mailAddress":"メールアドレス",
        "mobilePhone":"携帯電話",
        "password":"パスワード",
        "confirmPassword":"確認用パスワード",
        "status":"状態",
        "dirName":"企業会員管理",
    },
    "maintenanceUserPages":{
        "id":"ユーザーID",
        "userName":"ユーザー名",
        "mailAddress":"メールアドレス",
        "mobilePhone":"携帯電話",
        "password":"パスワード",
        "confirmPassword":"確認用パスワード",
        "status":"状態",
        "dirName":"ユーザー管理",
    },
    "maintenanceOperPages":{
        "id":"運営ユーザーID",
        "operUserName":"運営ユーザー名",
        "mailAddress":"メールアドレス",
        "mobilePhone":"携帯電話",
        "password":"パスワード",
        "confirmPassword":"確認用パスワード",
        "role":"ロール",
        "status":"状態",
        "add":"新規",
        "save":"保存",
        "back":"戻る",
        "dirName":"運営者管理",
    },
    "newPresidentPages":{
        "compId":"企業ID",
        "compName":"企業名",
        "title":"タイトル",
        "position":"役職",
        "name":"氏名",
        "firstIndustry":"出⾝業界",
        "industry":"業界",
        "jobTitle":"職種",
        "publicReservationDatetime":"公開予約日時",
        "displayOrder":"表示順",
        "status":"状態",
        "add":"新規",
        "save":"保存",
        "back":"戻る",
        "dirName":"新着社長管理",
        "newPresidentImageUrl":"新着社長画像",
    },
    "dashboardPages":{
        "graduationYear":"卒業年度",
        "userCount":"ユーザー登録数",
        "entryUserTotalCount":"エントリーユーザー総数",
        "meetPresidentEntryTotalCount":"社⻑に会えるエントリーユーザー総数",
        "dirName":"ダッシュボード",
    },
    "userPages":{
        "id":"ユーザーNo",
        "mail":"メールアドレス",
        "mailTypeName":"メールタイプ",
        "mobile":"電話番号",
        "mobileTypeName":"電話番号タイプ",
        "lastName":"氏名（姓）",
        "firstName":"氏名（名）",
        "lastNameRoma":"姓（カナ）",
        "firstNameRoma":"名（カナ）",
        "genderName":"性別",
        "birthday":"誕生日",
        "postCode":"郵便番号",
        "prefectureName":"都道府県",
        "addressCity":"都市区町村",
        "addressStreet":"番地等以降の住所",
        "addressBuilding":"建物名称",
        "schoolDivisionName":"学校区分",
        "schoolName":"学校名",
        "facultyDepartment":"学部・学科",
        "graduationYearName":"卒業年度",
        "sectionCategoryName":"文理区分",
        "aspiringOccupationName":"志望職種",
        "preferredWorkplaceName":"希望勤務地",
        "purposeName":"会員登録の⽬的",
        "purposeOther":"会員登録の⽬的その他",
        "whereYouKnowName":"どこで知りましたか",
        "whereYouKnowOther":"どこで知りその他",
        "mailDistributionName":"メール配信",
        "lineId":"line_id",
        "importFrom":"インポート区別",
        "statusName":"状態",
        "createTime":"登録日時",
        "lastLoginDatetime":"最終ログイン時間",
        "recruitAuditionCount":"RA参加回数",
        "dirName":"登録ユーザー情報",
    },
    "userDetailPages":{
        "userId":"ユーザーNo",
        "graduationYear":"卒業年度",
        "createDateTime":"登録日時",
        "userName":"氏名",
        "gender":"性別",
        "university":"大学名",
        "aspiringOccupation":"志望職種",
        "survey":"RecruitAudition参加フラグ",
        "recruitAuditionCount":"RecruitAudition参加回数",
        "sectionCategory":"文理区分",
        "lastLoginDatetime":"最終ログイン時間",
        "raParticipatingFlag":"RecruitAudition参加フラグ",
        "recruitAudition":"RecruitAudition名",
        "entry":"エントリー",
        "entryType":"エントリータイプ",
        "date":"エントリー日",
        "dirName":"ユーザー情報詳細",
    },
    "enterpriseEntryUserPages":{
        "entryName":"エントリー名",
        "entryType":"エントリータイプ",
        "entryMonth":"エントリー月",
        "entryDate":"エントリー日",
        "lastName":"氏名（姓）",
        "firstName":"氏名（名）",
        "userName":"氏名",
        "gender":"性別",
        "mailAddress":"メールアドレス",
        "schoolDivision":"学校区分",
        "schoolName":"学校名",
        "sectionCategory":"文理区分",
        "facultyDepartment":"学部",
        "memo":"メモ",
        "save":"保存",
        "back":"戻る",
        "dirName":"ユーザー詳細",
    },
    "enterpriseInquiryPages":{
        "inquiryType":"お問い合わせタイプ",
        "inquiryContent":"お問い合わせ内容",
        "submit":"送信",
        "back":"戻る",
        "dirName":"お問い合わせ",
    },
    "seminarPages":{
        "compName":"企業名",
        "forTheYear":"対象年度",
        "dateTimeDuration":"開催日時",
        "seminarTitle":"説明会タイトル",
        "seminarDetail":"説明会詳細",
        "imageUrl":"画像",
        "venue":"開催場所",
        "appTitle":"受付タイトル",
        "appDetails":"受付内容",
        "appContactInformation":"受付電話番号",
        "appContactMail":"受付メールアドレス",
        "appContactTime":"受付連絡時間",
        "remarks":"備考",
        "status":"状態",
        "preview":"プレビュー",
        "add":"新規",
        "save":"保存",
        "back":"戻る",
        "dirName":"セミナ説明会",
    },
    "seminarForCompPages":{
        "compName":"企業名",
        "forTheYear":"対象年度",
        "dateTimeDuration":"開催日時",
        "seminarTitle":"説明会タイトル",
        "seminarDetail":"説明会詳細",
        "imageUrl":"画像",
        "venue":"開催場所",
        "appTitle":"受付タイトル",
        "appDetails":"受付内容",
        "appContactInformation":"受付電話番号",
        "appContactMail":"受付メールアドレス",
        "appContactTime":"受付連絡時間",
        "remarks":"備考",
        "status":"状態",
        "preview":"プレビュー",
        "add":"新規",
        "save":"保存",
        "back":"戻る",
        "dirName":"セミナ説明会",
    },
    "compProfilePages":{
        "compName":"会社名",
        "compProfile":"会社概要",
        "compProfileImageUrl":"会社概要画像",
        "address":"住所",
        "dateOfSetting":"設⽴",
        "tel":"TEL",
        "numOfEmployees":"従業員数",
        "freeItem_01":"自由項目01",
        "freeItemDetail_01":"自由項目詳細01",
        "freeItem_02":"自由項目02",
        "freeItemDetail_02":"自由項目詳細02",
        "freeItem_03":"自由項目03",
        "freeItemDetail_03":"自由項目詳細03",
        "freeItem_04":"自由項目04",
        "freeItem_detail_04":"自由項目詳細04",
        "freeItem_05":"自由項目05",
        "freeItemDetail_05":"自由項目詳細05",
        "freeItem_06":"自由項目06",
        "freeItemDetail_06":"自由項目詳細06",
        "freeItem_07":"自由項目07",
        "freeItemDetail_07":"自由項目詳細07",
        "freeItem_08":"自由項目08",
        "freeItemDetail_08":"自由項目詳細08",
        "freeItem_09":"自由項目09",
        "freeItemDetail_09":"自由項目詳細09",
        "freeItem_10":"自由項目10",
        "freeItemDetail_10":"自由項目詳細10",
        "businessDescription":"事業内容",
        "publicReservationDatetime":"公開予約日時",
        "compPreview":"プレビュー",
        "businessPreview":"プレビュー",
        "add":"新規",
        "save":"保存",
        "back":"戻る",
        "dirName":"会社概要",
    },
    "snsSharePages":{
        "compName":"会社名",
        "title":"タイトル",
        "contents":"詳細",
        "imageUrl":"SNSシェア画像",
        "add":"新規",
        "save":"保存",
        "back":"戻る",
        "dirName":"SNSシェア",
    },
    "compInterviewPages":{
        "compName":"会社名",
        "interview":"インタビュー",
        "title_1":"タイトル1",
        "detail_1":"詳細1",
        "title_2":"タイトル2",
        "detail_2":"詳細2",
        "title_3":"タイトル3",
        "detail_3":"詳細3",
        "publicReservationDatetime":"公開予約日時",
        "preview":"プレビュー",
        "add":"新規",
        "save":"保存",
        "back":"戻る",
        "dirName":"インタビュー",
    },
    //07/18 add start
    "compInterviewPointerPages":{
        "compId":"企業ID",
        "compName":"企業名",
        "pointer1":"企業のポイント1",
        "pointer2":"企業のポイント2",
        "pointer3":"企業のポイント3",
        "pointer4":"企業のポイント4",
        "pointer5":"企業のポイント5",
        "pointer6":"企業のポイント6",
        "pointerSummary":"企業のポイント纏め",
        "catchCopy":"キャッチコピー",
        "interviewTitle1":"インタビュータイトル１",
        "interviewText1":"インタビュー本文１",
        "interviewImage1":"インタビュー画像１",
        "interviewTitle2":"インタビュータイトル２",
        "interviewText2":"インタビュー本文２",
        "interviewImage2":"インタビュー画像２",
        "interviewTitle3":"インタビュータイトル３",
        "interviewText3":"インタビュー本文３",
        "interviewImage3":"インタビュー画像３",
        "resumeText":"経歴本文",
        "resumeImage":"経歴画像",
        "publicReservationDatetime":"公開予約日時",
        "preview":"プレビュー",
        "status":"状態",
        "add":"新規",
        "save":"保存",
        "back":"戻る",
        "dirName":"インタビュー",
    },
    //07/18 add end

    "recruitmentRequirementsPages":{
        "compName":"会社名",
        "compId":"企業ID",
        "recrRequirementsTitle":"タイトル",
        "targetYear":"対象年度",
        "jobTitle":"職種",
        "jobDescription":"仕事内容",
        "recruitmentTarget":"募集対象",
        "workLocation":"勤務地",
        "salary":"給与",
        "modelSalaryExample":"モデル⽉収例",
        "variousSubsidy":"諸⼿当",
        "salaryIncrease":"昇給",
        "bonus":"賞与",
        "workingHours":"就業時間",
        "welfareBenefits":"福利厚⽣",
        "holidayVacation":"休日休暇",
        "recruitmentPoints":"求⼈のポイント",
        "telephone":"電話番号",
        "businessDescription":"事業内容",
        "appTitle":"受付タイトル",
        "appDetails":"受付内容",
        "appContactInformation":"受付電話番号",
        "appContactMail":"受付メールアドレス",
        "appContactTime":"受付連絡時間",
        "freeTitle_1":"自由項目タイトル1",
        "freeDetail_1":"自由項目詳細1",
        "freeTitle_2":"自由項目タイトル2",
        "freeDetail_2":"自由項目詳細2",
        "freeTitle_3":"自由項目タイトル3",
        "freeDetail_3":"自由項目詳細3",
        "freeTitle_4":"自由項目タイトル4",
        "freeDetail_4":"自由項目詳細4",
        "freeTitle_5":"自由項目タイトル5",
        "freeDetail_5":"自由項目詳細5",
        "freeTitle_6":"自由項目タイトル6",
        "freeDetail_6":"自由項目詳細6",
        "freeTitle_7":"自由項目タイトル7",
        "freeDetail_7":"自由項目詳細7",
        "freeTitle_8":"自由項目タイトル8",
        "freeDetail_8":"自由項目詳細8",
        "freeTitle_9":"自由項目タイトル9",
        "freeDetail_9":"自由項目詳細9",
        "freeTitle_10":"自由項目タイトル10",
        "freeDetail_10":"自由項目詳細10",
        "publicReservationDatetime":"公開予約日時",
        "status":"状態",
        "preview":"プレビュー",
        "add":"新規",
        "save":"保存",
        "back":"戻る",
        "dirName":"募集要項",
    },
    "informationPages":{
        "information_id":"インフォメーションID",
        "compName":"会社名",
        "informationTitle":"タイトル",
        "contents":"本文",
        "publicReservationDatetime":"公開予約日時",
        "imageUrl":"画像登録",
        "linkUrl":"リンクURL",
        "type":"タイプ",
        "memo":"メモ",
        "status":"状態",
        "preview":"プレビュー",
        "add":"新規",
        "save":"保存",
        "back":"戻る",
        "dirName":"インフォメーション管理",
    },
    "informationForCompPages":{
        "information_id":"インフォメーションID",
        "compName":"会社名",
        "informationTitle":"タイトル",
        "contents":"本文",
        "publicReservationDatetime":"公開予約日時",
        "imageUrl":"画像登録",
        "linkUrl":"リンクURL",
        "type":"タイプ",
        "memo":"メモ",
        "status":"状態",
        "preview":"プレビュー",
        "add":"新規",
        "save":"保存",
        "back":"戻る",
        "dirName":"インフォメーション管理",
    },
    "enterpriseNewsPages":{
        "enterpriseNewsId":"インフォメーションID",
        "newsTitle":"タイトル",
        "contents":"本文",
        "publicReservationDatetime":"公開予約日時",
        "imageUrl":"画像登録",
        "linkUrl":"リンクURL",
        "type":"タイプ",
        "status":"状態",
        "detail":"詳細",
        "back":"戻る",
        "dirName":"インフォメーション",
    },
    "googleAnalyticsPages":{
        "compId":"企業ID",
        "compName":"企業名",
        "pageViewNum":"ページビュー数",
        "sessionNum":"セッション数",
        "userNum":"ユーザー数",
        "entryPageRefUserNum":"エントリーページ参照ユーザー数",
        "presidentMeetEntryRefUserNum":"社長に会えるエントリー参照ユーザー数",
        "averagePageStayTime":"平均ページ滞在時間",
        "BounceRate":"直帰率",
        "back":"戻る",
        "dirName":"アナリティクス",
    },
    "maintenanceOperPrivPages":{
        "privId":"権限管理ID",
        "role":"ロール",
        "function":"機能名",
        "opertion":"操作名",
        "flag":"操作フラグ",    
        "add":"新規",
        "save":"保存",
        "back":"戻る",
        "dirName":"運営者権限管理",
    },
    //07/06 add start
    "compPhilosophyIntroductionPages":{
        "compId":"企業ID",
        "compName":"企業名",
        "philosophy":"企業理念",
        "thought":"理念に込められた想い",
        "episode":"理念体現を感じたエピソード",
        "introductionText":"会社紹介",
        "introductionImageUrl":"プロフィール画像",
        "publicReservationDatetime":"公開予約日時",
        "preview":"プレビュー",
        "status":"状態",
        "add":"新規",
        "save":"保存",
        "back":"戻る",
        "dirName":"企業理念とギャラリー",
    },
    //07/06 add end
    "meetPresidentEntryPages":{
        "meetPresidentEntryId":"社⻑に会えるエントリーID",
        "compName":"会社名",
        "imageUrl":"画像",
        "linkUrl":"リンクURL",
        "target":"参加対象",
        "meetPresidentEntryTitle":"タイトル",
        "contents":"詳細内容",
        "datetimeDuration":"開催日時",
        "venue":"開催場所",
        "venueDetail":"開催場所詳細",
        "capacity":"定員",
        "entryFee":"参加費",
        "assignmentsAndSubmissionMethods":"課題と提出⽅法",
        "deadline":"締切⽇",
        "flowUntilParticipation":"参加までのフロー",
        "passOrFail":"合否について",
        "status":"状態",
        "appTitle":"受付タイトル",
        "appDetails":"受付内容",
        "appContactInformation":"受付電話番号",
        "appContactEmail":"受付メールアドレス",
        "appContactDatetime":"受付連絡時間",
        "entryClosureFlag":"開催終了フラグ",
        "publicReservationDatetime":"公開予約日時",
        "preview":"プレビュー",
        "add":"新規",
        "save":"保存",
        "back":"戻る",
        "dirName":"社⻑に会えるエントリー",
    },
    "recruitAuditionCompPages":{
        "eventDate":"開催日付",
        "compName":"企業名",
        "position":"役職",
        "name":"氏名",
        "imageUrl":"画像",
        "linkUrl":"リンクURL",
        "displayOrder":"表示順",
        "publicReservationDatetime":"公開予約日時",
        "status":"状態",
        "add":"新規",
        "save":"保存",
        "back":"戻る",
        "dirName":"今月のRecruitAudition参加企業管理",
    },
    "eventEntryPages":{
        "eventId":"イベントID",
        "eventName":"イベント名",
        "eventDateTimeDuration":"開催日時",
        "target":"対象",
        "type":"タイプ",
        "areaPlaceId":"開催場所",
        "areaPlaceDetail":"開催場所詳細",
        "publicReservationDateTime":"公開予約日時",
        "status":"状態",

        "userId":"ユーザーNo",
        "mail":"メールアドレス",
        "mailType":"メールアドレスタイプ",
        "userName":"氏名",
        "gender":"性別",
        "graduationYear":"卒業年度",
        "university":"大学名",
        "aspiringOccupation":"志望職種",
        "sectionCategory":"文理区分",

        "entryDate":"エントリー日時",
        "entryPass":"エントリー合否",
        "memo":"メモ",

        "add":"新規",
        "save":"保存",
        "back":"戻る",
        "dirName":"ユーザーイベント登録",
    },
    "compPhilosophyEmpathyPages":{
        "compName":"会社名",
        "title":"タイトル",
        "compPhilosophy":"企業理念",
        "question":"質問",
        "answer_A":"回答_A",
        "answer_B":"回答_B",
        "correctAnswer":"正解",
        "publicReservationDatetime":"公開予約日時",
        "status":"状態",
        "preview":"プレビュー",
        "add":"新規",
        "save":"保存",
        "back":"戻る",
        "dirName":"理念共感チェック",
    },
    "youtubeLivePages":{
        "id":"動画ID",
        "videoUrl":"動画URL",
        "imageUrl":"画像",
        "eventInformation":"イベント情報",
        "timeDuration":"時間",
        "videoTitle":"動画タイトル",
        "publicReservationDatetime":"公開予約日時",
        "status":"状態",
        "memo":"メモ",

        "add":"新規",
        "save":"保存",
        "back":"戻る",
        "dirName":"YoutubeLive",
    },

};