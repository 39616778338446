module.exports = {
    "common":{
        "popup":"提示",
        "ok":"确定",
        "cancel":"取消"
    },
    "login":{
        "username":"用户名",
        "password":"密码",
        "login":"登录",
        "forgetPwd":"忘记密码",
        "slogan":"使用尖端的人工智能技术，重新定义我们的工作方式。通过业务流程自动化，不仅解放企业生产力，同时也驱动业务的附加值不断的提高。今后，小易将提供更多的云自动化服务，成为你身边的绩效助手。",
        "err1":"请输入用户名",
        "err2":"请输入密码"
    },
    "account":{
        "account":"账户",
        "info":"账户信息",
        "contactor":"名字",
        "mobile":"手机",
        "mail1":"联系邮件",
        "systemmail":"系统通知",
        "billmail":"账单",
        "update":"更新",
        "cancel":"取消",
        "err1":"请输入联系人",
        "err2":"长度在 1 到 20 个字符",
        "err3":"请输入联系人手机",
        "err4":"手机长度需是11位",
        "err5":"请输入联系邮件",
        "err6":"请输入正确的邮件地址"
    },
    "forgetPWD":{
        "mail":"联系邮件",
        "reset":"重置密码",
        "back":"返回登录",
        "err1":"请输入联系人邮件地址",
        "suc1":"密码重置成功，请查收邮件。"
    },
    "resetPWD":{
        "account":"账户",
        "udpatePWD":"密码修改",
        "oldPassword":"密码",
        "newPassword":"新密码",
        "newPassword2":"确认密码",
        "update":"更新",
        "err1":"两次输入密码不一致!",
        "err2":"请输入密码",
        "err3":"长度在 8 到 20 个字符",
        "err4":"请输入新密码",
        "err5":"请输入确认密码"
    },
    "addGroup":{
        "admin":"管理",
        "addRole":"创建小组",
        "role":"小组信息",
        "name":"组名",
        "process":"流程",
        "hint":"请选择流程",
        "add":"创建",
        "back":"返回",
        "err1":"请输入组名",
    },
    "addUser":{
        "admin":"管理",
        "addUser":"创建账户",
        "account":"账户信息",
        "contactor":"名字",
        "password":"密码",
        "A":"管理员",
        "B":"业务",
        "C":"财务",
        "T":"技术",
        "role":"角色",
        "group":"小组",
        "mobile":"手机",
        "mail1":"联系邮件",
        "systemmail":"系统通知",
        "billmail":"账单通知",
        "notice":"通知",
        "notnotice":"不通知",
        "create":"创建",
        "back":"返回",
        "err1":"请输入联系人!",
        "err2":"长度在 1 到 20 个字符",
        "err3":"请输入联系人手机",
        "err4":"手机长度需是11位",
        "err5":"请输入联系邮件",
        "err6":"请输入正确的邮件地址",
        "err7":"请输入密码",
        "err8":"请输入正确的密码"
    },
    "listGroup":{
        "group":"个角色",
        "add":"创建",
        "name":"小组",
        "bindprocess":"绑定流程",
        "process":"操作",
        "remove":"删除",
        "confirm":"确认要删除该角色?",
        "popup":"提示",
        "ok":"确定",
        "cancel":"取消"
    },
    "listUser":{
        "user":"个用户",
        "add":"创建",
        "name":"姓名",
        "role":"角色",
        "group":"小组",
        "process":"操作",
        "edit":"编辑",
        "remove":"删除",
        "confirm":"确认要删除该用户?",
        "popup":"提示",
        "ok":"确定",
        "cancel":"取消"
    },
    "updateUser":{
        "manage":"管理",
        "updateUser":"修改账户",
        "update":"更新",
        "err1":"请输入联系人!",
        "err2":"长度在 1 到 20 个字符",
        "err3":"请输入联系人手机",
        "err4":"手机长度需是11位",
        "err5":"请输入联系邮件",
        "err6":"请输入正确的邮件地址",
        "err7":"请输入密码",
        "err8":"请输入正确的密码"
    },
    "payReport":{
        "totalCount":"件",
        "month":"月份",
        "functionName":"功能",
        "currentPlan":"套餐",
        "extraFee":"额外扣费",
        "totalFee":"费用变动",
        "balance":"账户余额",
        "date":"扣款时间"
    },
    "processReview":{
        "auto":"自动",
        "manual":"人工",
        "command":"指令",
        "resource":"资源",
        "back":"返回"
    },
    "process":{
        "my":"我的流程",
        "start":"启动中...",
        "stop":"停止中...",
        "confirm1a":"确定要上线",
        "confirm1b":"流程吗？",
        "confirm2a":"确定要下线",
        "confirm2b":"流程吗？"
    },
    "monitor":{
        "input":"处理文件",
        "robotName":"机器人",
        "functionName":"功能",
        "date":"日期",
        "start":"开始",
        "end":"结束",
        "result":"结果",
        "process":"操作",
        "redo":"再执行",
        "unit":"件"
    },
    "myrobot":{
        "confirm1a":"确定要",
        "confirm1b":"开始工作吗？",
        "confirm2a":"确定要",
        "confirm2b":"停止工作吗？",
        "confirm3a":"确定要",
        "confirm3b":"检查升级吗？",
        "start":"启动中...",
        "stop":"停止中...",
    },
    "runningReport":{
        "graph":"仪表盘",
        "calling":"总调用次数",
        "durationAvg":"平均执行时间（秒）",
        "persent":"成功率",
        "newMonth":"报表期间",
        "month":"选择月",
        "date":"日期",
        "robotName":"机器人",
        "functionName":"功能",
        "callSum":"调用次数",
        "callSuccess":"成功次数",
        "callFail":"失败次数",
        "duration":"执行时间（秒）",
        "list":"一览",
        "unit":"件"
    },
    "planList":{
        "set":"套餐信息",
        "function":"功能",
        "plan":"套餐名称",
        "start":"有效期开始",
        "end":"有效期结束",
        "amount":"余量（次）",
        "balance":"余额（元）"
    },
    "menu":{
        "process":"流程",
        "robot":"机器人",
        "cloud":"云服务",
        "report":"报表",
        "bill":"账单",
        "admin":"管理",
        "user":"用户",
        "group":"小组",
        "account":"账户信息",
        "password":"密码修改",
        "logout":"退出"
    },
    "processItem":{
        "online":"上线",
        "offline":"下线",
        "workday":"工作日",
        "day":"每日",
        "detail":"详细",
        "ver":"版"
    },
    "robotItem":{
        "online":"启动",
        "offline":"停止",
        "config":"配置",
        "update":"升级",
        "ver":"版"
    },
    "robotConfig":{
        "config":"-配置",
        "exe":"执行方式：",
        "by":"间隔",
        "bytime":"定点",
        "time":"间隔时间：",
        "unit":"请选择间隔单位",
        "min":"分钟",
        "hour":"小时",
        "day":"每天：",
        "selecttime":"选择时间",
        "weekend":"包含周末",
        "hint":"配置成功后请重新启动"
    },
    "report":{
        "function":"功能",
        "byfunction":"功能执行次数统计",
        "byrobot":"机器人执行次数统计",
        "bymonth":"月度执行次数统计",
    },
    "search":{
        "search":"检索",
        "reset":"重置",
        "clear":"清空",
        "selMonth":"请选择月份",
        "selDuration":"请选择期间",
        "selFunction":"请选择功能",
        "today":"当日",
        "week":"当周",
        "month":"当月",
        "selStatus":"请选择状态",
        "success":"成功",
        "fail":"失败",
        "running":"运行中",
        "selRobot":"请选择机器人",
        "file":"文件",
        //TASK
        "created":"未认领",
        "signed":"已认领",
        "saved":"已保存",
        "completed":"已完成",
        "deleted":"已删除",
        "keyword":"关键字/认领人",
        "out":"出库单",
        "compare":"合同比较",
        "customer":"自动报关",
        "OrgPic":"原图"
    },
    "task": {
        "view": "查看",
        "type":"类型",
        "keyword":"客户名称",
        "createtime":"创建时间",
        "assigntime":"认领时间",
        "endtime": "完成时间",
        "interval":"间隔",
        "assigner":"认领人",
        "status":"状态",
        "process":"操作",
        "assign":"认领",
        "delete":"删除",
        "truncate":"彻底删除",
        "rollback":"还原",
        "file":"处理文件",
        "robot":"机器人",
        "date":"日期",
        "start":"开始时间",
        "end":"结束时间",
        "info":"信息",
        "compare":"比较",
        "redo":"再执行"
    },
    "taskDetail": {
        "upload": "上传图片", 
        "repeatCheck": "查重", 
        "print": "打印",
        "save":"保存",
        "submit":"提交",
        "delete":"删除",
        "back":"返回",
        "addRow":"新增行",
        "openOrgPic":"查看原图",
        "recSeal":"印章识别",
        "storeCmt":"仓库备注",
        "autoPick":"自动拣货"
    }
    
}