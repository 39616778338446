export const LOCAL_STORAGE_KEY = {
  RPA_USER_ID: "RPA_USER_ID",
  RPA_LOGIN_TYPE: "RPA_LOGIN_TYPE",
  RPA_TOKEN: "RPA_TOKEN",
  RPA_TOKEN_EXPIRE: "RPA_TOKEN_EXPIRE",
  COMP_ID: "COMP_ID",
  SEARCH_PARAMS_COMP: "SEARCH_PARAMS_COMP",
    USER_INFO: "USER_INFO",
};

export const ROUTER_PATH={
    HOME:'/robot/myRobot',
    LOGIN:'/login',

    MODIFY: '/modify',

    INDEX: '/',

    INDEX_2: '/index',
    AE: '/index/ae',
    ENTRY_LEVEL: '/index/entryLevel',
    ENTRY_LEVEL_DETAIL: '/index/entryLevelDetail',
    
    SPECIAL_ENTRY: '/index/specialEntry',
    SPECIAL_ENTRY_DETAIL: '/index/specialEntryDetail',

    EXPLAIN_MEETING: '/index/explainMeeting',
    EXPLAIN_MEETINGADD: '/index/explainMeetingAdd',
    HELP: '/index/help',
    NOTICE: '/index/notice',
    PERSONA: '/index/persona',
    PROCESS_DESIGN: '/index/processDesign',
    PROCESS_OPERATION: '/index/processOperation',
    STU_KNOW: '/index/stuKnow',
    DELIVERY_SETTINGS: '/index/deliverySettings',
    ACCOUNT: '/index/account',
    ACCOUNT_FORM: '/index/accountForm',
    SCOUT: '/index/scout',
    SAVE_LIST: '/index/saveList',

    LOGIN_OUT:"/account/loginOut",
    SYS_CONFIG: "/config/sysConfig",

    TOP_SLIDE:"/pprh/SlideList",

    SLIDE_LIST:"/pprh/SlideList",
    SLIDE_ADD:"/pprh/SlideAdd",

    VIDEO_LIST:"/pprh/VideoList",
    VIDEO_ADD:"/pprh/VideoAdd",

    NEWS_LIST:"/pprh/NewsList",
    NEWS_DETAIL:"/pprh/NewsDetail",

    SENDNEWS_LIST:"/pprh/SendNewsList",
    SENDNEWS_DETAIL:"/pprh/SendNewsDetail",

    TAG_LIST:"/pprh/TagList",
    TAG_DETAIL:"/pprh/TagDetail",

    TAGDETAIL_LIST:"/pprh/TagDetailList",
    TAGDETAIL_DETAIL:"/pprh/TagDetailDetail",

    EVENT_LIST:"/pprh/EventList",
    EVENT_ADD:"/pprh/EventAdd",

    EVENTCOMPANY_LIST:"/pprh/EventCompanyList",
    EVENTCOMPANY_ADD:"/pprh/EventCompanyAdd",

    EVENTAREAPLACE_LIST:"/pprh/EventAreaPlaceList",
    EVENTAREAPLACE_ADD:"/pprh/EventAreaPlaceAdd",

    COMPSIGNUP_LIST:"/pprh/compSignupList",
    COMPSIGNUP_ADD:"/pprh/compSignupAdd",

    COMPMAILADDRESS_LIST:"/pprh/compMailAddressList",
    COMPMAILADDRESS_ADD:"/pprh/compMailAddressAdd",

    COMPPHILOSOPHYCHECK_LIST:"/pprh/compPhilosophyCheckList",
    COMPPHILOSOPHYCHECKDETAIL_LIST:"/pprh/compPhilosophyCheckDetailList",

    COMPENTRYUSER_LIST:"/pprh/compEntryUserList",
    COMPENTRYUSERDETAIL_LIST:"/pprh/compEntryUserDetailList",

    LOG_LIST:"/pprh/logList",

    MAINTENANCECOMP_LIST:"/pprh/maintenanceCompList",
    MAINTENANCECOMP_ADD:"/pprh/maintenanceCompAdd",

    MAINTENANCEOPER_LIST:"/pprh/maintenanceOperList",
    MAINTENANCEOPER_ADD:"/pprh/maintenanceOperAdd",

    MAINTENANCEUSER_LIST:"/pprh/maintenanceUserList",

    NEWPRESIDENT_LIST:"/pprh/newPresidentList",
    NEWPRESIDENT_ADD:"/pprh/newPresidentAdd",

    DASHBOARD_LIST:"/pprh/dashboardList",

    USER_LIST:"/pprh/userList",
    USERDETAIL_LIST:"/pprh/userDetailList",

    ENTERPRISEENTRYUSER_LIST:"/pprh/enterpriseEntryUserList",
    ENTERPRISEENTRYUSER_UPDATE:"/pprh/enterpriseEntryUserUpdate",

    ENTERPRISEINQUIRY_FORM:"/pprh/enterpriseInquiryForm",

    ADMIN_USER:"/pprh/adminUser",
    ADD_USER:"/pprh/addUser",
    UPDATE_USER:"/pprh/updateUser",

    SEMINAR_ADD:"/pprh/seminarAdd",
    SEMINAR_LIST:"/pprh/seminarList",

    SEMINARFORCOMP_ADD:"/pprh/seminarForCompAdd",
    SEMINARFORCOMP_LIST:"/pprh/seminarForCompList",

    BUSINESSPROFILE_ADD:"/pprh/businessProfileAdd",
    BUSINESSPROFILE_LIST:"/pprh/businessProfileList",

    COMPPROFILE_ADD:"/pprh/compProfileAdd",
    COMPPROFILE_LIST:"/pprh/compProfileList",

    COMPINTERVIEW_LIST:"/pprh/compInterviewList",
    COMPINTERVIEW_ADD:"/pprh/compInterviewAdd",

    //07/18 add start
    COMPINTERVIEWPOINTER_LIST:"/pprh/compInterviewPointerList",
    COMPINTERVIEWPOINTER_ADD:"/pprh/compInterviewPointerAdd",
    //07/18 add end

    KBN_MASTER:"/master/ListMaster",
    ADD_MASTER:"/master/AddMaster",
    UPDATE_MASTER:"/master/UpdateMaster",

    RECRUITMENTREQUIREMENTS_ADD:"/pprh/recruitmentRequirementsAdd",
    RECRUITMENTREQUIREMENTS_LIST:"/pprh/recruitmentRequirementsList",

    INFORMATION_ADD:"/pprh/informationAdd",
    INFORMATION_LIST:"/pprh/informationList",

    INFORMATIONFORCOMP_ADD:"/pprh/informationForCompAdd",
    INFORMATIONFORCOMP_LIST:"/pprh/informationForCompList",

    ENTERPRISENEWS_DETAIL:"/pprh/enterpriseNewsDetail",
    ENTERPRISENEWS_LIST:"/pprh/enterpriseNewsList",

    GOOGLEANALYTICS_LIST:"/pprh/googleAnalyticsList",

    MAINTENANCEOPERPRIV_LIST:"/pprh/maintenanceOperPrivList",
    MAINTENANCEOPERPRIV_ADD:"/pprh/maintenanceOperPrivAdd",

    //07/06 add start
    COMPPHILOSOPHYINTRODUCTION_ADD:"/pprh/compPhilosophyIntroductionAdd",
    COMPPHILOSOPHYINTRODUCTION_LIST:"/pprh/compPhilosophyIntroductionList",
    //07/06 add end

    SNSSHARE_ADD:"/pprh/snsShareAdd",

    MEETPRESIDENTENTRY_LIST:"/pprh/meetPresidentEntryList",
    MEETPRESIDENTENTRY_ADD:"/pprh/meetPresidentEntryAdd",

    RECRUITAUDITIONCOMP_LIST:"/pprh/recruitAuditionCompList",
    RECRUITAUDITIONCOMP_ADD:"/pprh/recruitAuditionCompAdd",

    EVENTENTRY_LIST:"/pprh/eventEntryList",
    EVENTENTRY_ADD:"/pprh/eventEntryAdd",

    COMPPHILOSOPHYEMPATHY_ADD:"/pprh/compPhilosophyEmpathyAdd",
    COMPPHILOSOPHYEMPATHY_LIST:"/pprh/compPhilosophyEmpathyList",

    COMPPHILOSOPHYEMPATHY_QA_ADD:"/pprh/compPhilosophyEmpathyQAAdd",
    COMPPHILOSOPHYEMPATHY_QA_LIST:"/pprh/compPhilosophyEmpathyQAList",

    YOUTUBELIVE_LIST:"/pprh/youtubeLiveList",
    YOUTUBELIVE_ADD:"/pprh/youtubeLiveAdd",

    COMP_NON_PUBLIC_LIST:"/pprh/compNonPublicList",

}
