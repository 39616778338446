import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";
import initialValue from "./initialValue";

Vue.use(Vuex);

const state = {
  loading: false,
  accountInfo: initialValue.accountInfo,    
  loginPrePath:"/pprh/SlideList" //"/ac"
};

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations
});
