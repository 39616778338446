// 设置 rem 函数
function setRem() {
	let pageWidth = window.innerWidth;
	//为了ie也能拿到可视窗口宽度
	if (typeof pageWidth != "number") {
		//标准模式
		if (document.compatMode == "CSS1Compat") {
			pageWidth = document.documentElement.clientWidth;
			//怪异模式
		} else {
			pageWidth = document.body.clientWidth;
		}
	}
	let baseSize = 10;
	if (pageWidth >= 1000) {
		// 当前页面宽度相对于 1000 宽的缩放比例，可根据自己需要修改。
		let scale = pageWidth*baseSize/1000;
		// 设置页面根节点字体大小
		document.documentElement.style.fontSize = (scale) + 'px'
	}  else {
		// 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改。
		let scale = 1000*baseSize/1000;
		// 设置页面根节点字体大小
		document.documentElement.style.fontSize = (scale) + 'px'
	}
}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function () {
	setRem()
	// console.log("ssssss")
}

