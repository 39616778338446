<template>
  <div id="app">
    <router-view></router-view>
    <loading-mask v-show="loading" />
  </div>
</template>

<script>
import LoadingMask from "@/components/common/LoadingMask.vue";
import { mapState } from "vuex";
export default {
  name: "App",
  components: { LoadingMask },
  computed: {
    ...mapState(["loading"])
  }
};
</script>

<style lang="less">

@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);

* {
  margin: 0;
  padding: 0;
}

html,
body,
#app {
  min-height: 100vh;
  /*height: 100vh;*/
  background-color: #e9ecef;
  font-family: Noto Sans JP;
}

body {
  overflow: auto !important;
  padding-right: 0 !important;

}


body,html{
  /*min-width: 1280px;*/
  min-width: 1000px;
  width: 100%;
  /*overflow-x: hidden !important;*/
}

html {
  /*overflow-x: hidden;*/
}

@media only screen and (min-width: 1000px) {
  body,html{
    overflow-x: hidden !important;
  }
}

.dash-box{
  width: 90%;
  height: 60px;
  border: 1px solid #e9ecef;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
}
.dash-title{
  font-size: 11px;
  color: #909399;
}
.dash-value{
  font-size: 24px;
  font-weight: 500;
}
.dash-num-orange{
  color: #E6A23C;
}
.dash-num-blue{
  color: #409EFF;
}
.dash-num-green{
  color: #67C23A;
}
.btns .el-button {
  margin-right: 5px;
  margin-bottom: 1px;
  margin: 5px !important;
}



/*去除a标签下划线*/
a {
  text-decoration: none;
  color: white;
}
/*去除未被访问的a标签的下划线*/
a:link {
  text-decoration: none;
}
/*去除已经被访问过的a标签的下划线*/
a:visited {
  text-decoration: none;
  color: white;
}
/*去除鼠标悬停时的a标签的下划线*/
a:hover {
  text-decoration: none;
}
/*去除正在点击的a标签的下划线（鼠标按下，尚未松开）*/
a:active {
  text-decoration: none;
}
/*去除获得焦点的a标签的下划线（被鼠标点击过）*/
a:focus {
  text-decoration: none;
}


.ellipsis{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.ellipsis-2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;

  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
}


.ellipsis-3 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;

  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
}


.ellipsis-4 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;

  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
}
.ellipsis-5 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;

  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
}


body, div, span, header, footer, nav, section, aside, article, ul, dl, dt, dd, li, a, p, h1, h2, h3, h4,h5, h6, i, b, textarea, button, input, select, figure, figcaption {
  padding: 0;
  margin: 0;
  list-style: none;
  font-style: normal;
  text-decoration: none;
  border: none;
  -webkit-tap-highlight-color:transparent;
  -webkit-font-smoothing: antialiased;

}

body:focus, div:focus, span:focus, header:focus, footer:focus, nav:focus, section:focus, aside:focus, article:focus, ul:focus, dl:focus, dt:focus, dd:focus, li:focus, a:focus, p:focus, h1:focus, h2:focus, h3:focus, h4:focus,h5:focus, h6:focus, i:focus, b:focus, textarea:focus, button:focus, input:focus, select:focus, figure:focus, figcaption:focus {
  outline: none;
}

.top-text {
  font-size: 1.3rem;

  font-style: normal;
  font-weight: 700;
  /* identical to box height */
  color: #424242;
  border-top: 1px transparent solid;
  background-color: white;
  border-bottom: 0.78125px solid #ADADAD;
}

.top-text p {
  margin: 1.3rem 0 1.3rem 3.1rem;
}


.el-tooltip__popper {
  /*font-size: .9rem !important;*/
  max-width: 13.2rem;
  //mix-blend-mode: normal;

  font-style: normal;
  font-weight: 500;
  font-size: .78125rem;
  line-height: 1.2rem;
  /* or 150% */
  /*background: rgba(66, 66, 66, 0.95);*/
  //mix-blend-mode: multiply;
  border-radius: .234375rem;

  color: #FFFFFF;
}

.el-tooltip__popper {

}


.blue-box {
  .el-message-box {

  }

  min-width: 30.859rem !important;
  padding: 0.8rem 0 2rem 0 !important;
  box-sizing: border-box;
  width: auto !important;
  /*border: .390625rem solid #15B1CE !important;*/
  border-radius: .78125rem;

  .el-message-box__header {
    padding: 0;
  }

  .el-message-box__header .el-message-box__title span {
    display: block;
    font-style: normal;
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 2rem;
    /* or 108% */

    text-align: center;
    color: #15B1CE;
  }

  .el-message-box__content {
    padding: .4rem 0;
  }

  .el-message-box__content .el-message-box__message p {

    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 2rem;
    /* or 162% */

    text-align: center;

    color: #000000;
  }


 /* .el-message-box__btns {
    padding: 0;
    display: flex;
    align-items: center;
    margin-top: 1.559rem;
    justify-content: center;
  }

  .el-message-box__btns>button:first-child {
    width: 10.938rem;
    height: 3.125rem;

    background: #FFFFFF;
    border: .117188rem solid #A3A3A3;
    border-radius: .78125rem;
    margin-right: 1.563rem;
  }

  .el-message-box__btns>button:first-child span {

    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    !* identical to box height, or 125% *!

    text-align: center;

    color: #A3A3A3;
  }*/

  /*.el-message-box__btns>button:last-child {
    width: 10.938rem;
    height: 3.125rem;

    background: #2876F6;
    border: .117188rem solid #A3A3A3;
    border-radius: .78125rem;
  }

  .el-message-box__btns>button:last-child span {

    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    !* identical to box height, or 125% *!

    text-align: center;

    color: #FFFFFF;
  }*/


}

.el-dropdown-menu {
  max-height: 10rem;
  overflow-y: auto;
  //overflow-x: hidden;
}


.el-dropdown-menu::-webkit-scrollbar
{
  width: .3rem;
  height: 3.9rem;
  background: transparent;
  /*background-color: red;*/
  /*background-color: #DADADA;*/
}

.el-dropdown-menu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0);
  border-radius: 1.5rem;
  background-color: white;

}

.el-popper[x-placement^="bottom"] {
  transform: translateY(-12px);
}

.el-dropdown-menu__item {
  line-height: normal !important;
}

.el-dropdown-menu__item img {
  width: 1.638rem;
  height: 1.563rem;
}

.el-dropdown-menu__item img {

}

.el-dropdown-menu::-webkit-scrollbar-thumb
{
  /*border-radius: 10px;*/
  background-color: #DADADA;
  border-radius: 1.5rem;
}

.page-col{padding-top:1rem;text-align: center;}
.el-pagination.is-background .el-pager li:not(.disabled).active
{
  background: #15B1CE!important;
}

.el-popover {
  padding: 0!important;
  background: transparent !important;
  width: auto !important;
  border: none !important;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0) !important;
}

.popper__arrow {
  display: none !important;
}

/* 确认窗 取消确认按钮位置更换 */
.el-message-box__wrapper .el-message-box__btns {
  display: flex;
  flex-flow: row-reverse;
}

.el-message-box__wrapper .el-message-box__btns .el-button {
  margin-left: 10px;background: #fff;border: 1px solid #DCDFE6;color:#A3A3A3;border-radius: 10px;
}

.el-message-box__wrapper .el-message-box__btns .el-button:hover{
  background: #2876F6;color:#fff;
}

.el-message-box__wrapper .el-message-box__btns {
  justify-content: center;
}

.el-message-box__wrapper .el-message-box__btns button {
  width: 8.5938rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  //line-height: 2.5rem;
}

.yellow-btn:hover{
  background: #F9B125!important;color:#fff!important;border-color: #F9B125!important;
}

.green-btn:hover{
  background: #3CB55E!important;color:#fff!important;border-color: #3CB55E!important;
}

.cf-btn:hover{
  background: #15B1CE!important;color:#fff!important;border-color: #15B1CE!important;
}
.view-btn:hover{
  border: 1px solid #424242!important;color:#424242!important;
}


</style>
