const filters = {
    timeFormat1: function(time) {
        var date = new Date(time);
        var year = date.getFullYear();

        var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
        var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
        return month + "/" + day + " " + hours + ":" + minutes;

    },
    monthFormat: function(time) {
        var date = new Date(time);
        var year = date.getFullYear();

        var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
        return year + "/" + month;

    },
    timeFormat2: function(time) {
        var date = new Date(time);
        var year = date.getFullYear();

        var month = date.getMonth() + 1;
        var day = date.getDate();
        return year + '年' + month + '月' + day + '日';

    } ,
    timeFormat3: function(time) {
        var date = new Date(time);
        var year = date.getFullYear();

        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
        //返回时间格式  yyyy-mm-dd hh:mm:ss
        return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
        // return year + '年' + month + '月' + day + '日';

    },
    timeFormat4: function(time) {
        var date = new Date(time);
        var year = date.getFullYear();

        var month = date.getMonth() + 1;
        var day = date.getDate();
        month = month < 10 ? "0" + month : month
        return year + '/' + month  + '/' + day;

    } ,
    money_point: function(val) {
        val = val.toString().replace(/\$|\,/g, '');
        if (isNaN(val)) {
            val = "0";
        }
        let sign = (val == (val = Math.abs(val)));
        val = Math.floor(val * 100 + 0.50000000001);
        let cents = val % 100;
        val = Math.floor(val / 100).toString();
        if (cents < 10) {
            cents = "0" + cents
        }
        for (var i = 0; i < Math.floor((val.length - (1 + i)) / 3); i++) {
            val = val.substring(0, val.length - (4 * i + 3)) + ',' + val.substring(val.length - (4 * i + 3));
        }

        // return (((sign)?'':'-') + val + '.' + cents);
        return (((sign) ? '' : '-') + val);
    },
    moneyFormat: function(number) {
        if (!number) return '-';
        var number=(number || 0).toString();
        var result='';
        var num = number;
        var array = []
        if (number.indexOf(".") > -1) {
            array = number.split(".");
            num = array[0]
        }
        while(num.length>3){
            result=','+num.slice(-3)+result;
            num=num.slice(0,num.length-3);
        }
        if(num){
            result=num+result;
        }
        if (number.indexOf(".") > -1) {
            result = result + "." + array[1]
        }
        return result;
    },
    statusFormat(status) {
        if (status == 1) {
            return '有効';
        } else {
            return '無効';
        }
    },
    entryTypeFormat(type) {
        if (type == 1) {
            return '説明会';
        } else  if (type == 2) {
            return '社長に会えるエントリー';
        } else  if (type == 3) {
            return '募集事項';
        } else  if (type == 4) {
            return 'イベント';
        } else {
            return '';
        }
    },
    // genderFormat(type) {
    //     if (type == 1) {
    //         return '男性';
    //     } else  if (type == 2) {
    //         return '女性';
    //     } else  if (type == 3) {
    //         return 'その他';
    //     } else {
    //         return '';
    //     }
    // },
    // informationTypeFormat(type) {
    //     if (type == 1) {
    //         return 'News';
    //     } else  if (type == 2) {
    //         return 'Release';
    //     } else  if (type == 3) {
    //         return 'Topics';
    //     } else {
    //         return '';
    //     }
    // },
    entryTypeNewFormat(type) {
        if (type == 1) {
            return '説明会エントリー';
        } else if (type == 2) {
            return '社長に会えるエントリー';
        } else if (type == 3) {
            return '通常エントリー';
        } else {
            return '';
        }
    },
    // showNewFormat(publicReservationDatetime) {
    //     var date1 = new Date(publicReservationDatetime);
    //     var year1 = date1.getFullYear();
    //     var month1 = date1.getMonth() + 1;
    //     var day1 = date1.getDate();
    //     return "New";
    // }
}

export default (vm) => {
    Object.keys(filters).forEach(key => {
        vm.filter(key, filters[key])
    })
}