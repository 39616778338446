import axios from "axios";
import { mutationsType } from "@/store/mutations-type.js";
import store from "@/store";
import { Message } from "element-ui";
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
import router from "../router";
import { ROUTER_PATH } from "@/constants/common.js";
const axiosRequest = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  //   baseURL: 'http://123.60.4.162:87/ppcl',
    timeout: 30000
});
let needLoadingCount = 0;
let needReject = true;
const showFullScreenLoading = () => {
  if (needLoadingCount === 0) {
    store.commit(mutationsType.SHOW_LOADING);
  }
  needLoadingCount++;
};
const hideFullScreenLoading = () => {
  if (needLoadingCount <= 0) {
    return;
  }
  needLoadingCount--;
  if (needLoadingCount === 0) {
    store.commit(mutationsType.HIDE_LOADING);
  }
};

// const getResponseErrorMsg = errorCode => {
//   // TODO
//   console.log(errorCode);

//   return "code is error";
// };

axiosRequest.interceptors.request.use(
  config => {
    showFullScreenLoading();
    const token = localStorage.getItem(LOCAL_STORAGE_KEY.RPA_TOKEN);
    const wkVersion = process.env.VUE_APP_VERSION;
    // config.headers["Access-Control-Allow-Origin"]= "*";
    //   console.log("sss")
      config.headers["Content-Type"]= "application/json;charset=UTF-8";
    const expire = localStorage.getItem(LOCAL_STORAGE_KEY.RPA_TOKEN_EXPIRE);
    if (token) {
      //if (Date.now() < expire) {
        config.headers.authorization = `${token}`;
        // config.data.token = `${token}`;
      //}
    }
    if (wkVersion) {
        config.headers.WorkstationVersion = `${wkVersion}`;
    }
    needReject = true;
    //06/09 avoid undefined property error message
    if (typeof(config.data) == 'object') {
      if (config.data.hasOwnProperty('needReject') == true) {
        if (config.data.needReject == false) {
          needReject = false;
        }
      } else {
        needReject = false;
      }
    } else {
      needReject = false;
    }
/*
    if (config.data.needReject == false) {
        needReject = false;
    }
*/
    // console.log(config)
    return config;
  },
  error => {
    // do something with request error
    hideFullScreenLoading();
    Message({
      showClose: true,
      message: error || "Request Error",
      type: "error"
    });

    return Promise.reject(error);
  }
);
// response interceptor
axiosRequest.interceptors.response.use(
  response => {
    hideFullScreenLoading();
    const res = response.data;


    if(res.code==401){
        console.log("登录",res);
        window.location.href="/#/login";
    }

    if (res.code !== "000") {
      // const errorMessage = getResponseErrorMsg(res.code);

        if (response.config.url.indexOf("/userBasicExport") != -1
            || response.config.url.indexOf("/userEntryExport") != -1
            || response.config.url.indexOf("/importCareer") != -1)
        {
            return res;
        } else {
            if(res.message) {
                Message({
                    showClose: true,
                    message: res.message,
                    type: "error"
                });
                // TODO:ERROR处理
            }

            if (res.code === "666") {
                console.log("code error!");
                // router.push(ROUTER_PATH.DASHBOARD_LIST);
            }
            if (needReject) {
                return Promise.reject(new Error(res.message || "Data Error"));
            } else {
                return res;
            }
        }
    } else {
      return res;
    }
  },
  error => {
    hideFullScreenLoading();
    console.log(error)
      console.log("error.response", error);
      if(error.toString().indexOf('Network Error') > 0) {
          localStorage.removeItem("USER_INFO");
          localStorage.removeItem("RPA_TOKEN");
          window.location.href="/#/login";
      }
      // console.log("error.response.status", error.response.status);
      // if (error.response.status==504) {
      //     Message.error({message:'Gateway Timeout'})
      // }else if(error.response.status==403){
      //     Message.error({message:'Forbidden'})
      // }else if (error.response.status==401){
      //     //Message.error({message:'Unauthorized, Please login'});
      //     router.replace(ROUTER_PATH.LOGIN);
      // }else if (error.response.status==404){
      //     Message.error({message:'Not Found'})
      // } else if (error.response.status==500){
      //     Message.error({message:'Internal Server Error'})
      // } else {
          Message({
              showClose: true,
              message: error.message,
              type: "error"
          });
      // }
      return Promise.reject(error);
  }
);

export default axiosRequest;
