import Vue from "vue";
import App from "./App.vue";
import Element from 'element-ui';
import './element-variables.scss'

// import * as echarts from 'echarts/core';

import VueCookies from 'vue-cookies';
import VueI18n from 'vue-i18n';
import zhlocale from "element-ui/lib/locale/lang/zh-CN";
import jalocale from "element-ui/lib/locale/lang/ja";
import router from "./router";
import store from "./store";
import filters from './filter'
import rem from  './utils/rem'
import echarts from  './utils/echarts'

//import "./plugins/element.js";
import "normalize.css";
import fontawesome from "@fortawesome/fontawesome";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import solid from "@fortawesome/fontawesome-free-solid";
import regular from "@fortawesome/fontawesome-free-regular";
import brands from "@fortawesome/fontawesome-free-brands";

import moment from "moment"
Vue.prototype.$moment = moment;


import AdminTitle from '@/components/adminTitle'
Vue.component('AdminTitle',AdminTitle)

import axiosRequest from "@/utils/axiosRequest";

import { isNullOrUndefinedOrEmpty } from "@/utils/stringHelper.js";
import { mutationsType } from "@/store/mutations-type.js";
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
import { ROUTER_PATH } from "@/constants/common.js";

/*
//2022/04/10
//to be updated after getting GTM single container ID start gtm block
import VueGtm from "vue-gtm";

Vue.use(VueGtm, {
  id: "GTM-P6RD2VG", // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
//  queryParams: {
//    // Add url query string when load gtm.js with GTM ID (optional)
//    gtm_auth: "AB7cDEf3GHIjkl-MnOP8qr",
//    gtm_preview: "env-4",
//    gtm_cookies_win: "x",
//  },
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: ["homepage"], // Don't trigger events for specified router names (case insensitive) (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});

//end gtm block
*/

filters(Vue);
Vue.use(VueI18n);
Vue.use(VueCookies);

const messages = {
  ja: Object.assign(require("./assets/languages/jp"),jalocale),
  zh: Object.assign(require("./assets/languages/zh"),zhlocale)
}


// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: "ja", // set locale
  messages// set locale messages
});

Vue.use(Element,{
  i18n: (key, value) => i18n.t(key, value)
});

fontawesome.library.add(solid);
fontawesome.library.add(regular);
fontawesome.library.add(brands);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.prototype.$axios = axiosRequest;

Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
    // next();
    if (!to.meta.needLogin) {
        next();
    } else {
        let token = localStorage.getItem(LOCAL_STORAGE_KEY.RPA_TOKEN)
        if(token == null || token == undefined || token == '') {
            store.commit(mutationsType.SET_LOGIN_PRE_PATH, to.path);
            next(ROUTER_PATH.LOGIN);
        } else {
            next();
        }
    }

  // next();
  // if (!to.meta.needLogin) {
  //   next();
  // } else {
  //   if(to.path===ROUTER_PATH.LOGIN_OUT){
  //
  //
  //     store.commit(mutationsType.SET_LOGIN_PRE_PATH, from.path);
  //     next();
  //   }
  //   else if (
  //     isNullOrUndefinedOrEmpty(
  //       localStorage.getItem(LOCAL_STORAGE_KEY.RPA_TOKEN)
  //     ) ||
  //     isNullOrUndefinedOrEmpty(
  //       localStorage.getItem(LOCAL_STORAGE_KEY.RPA_USER_ID)
  //     )
  //   ) {
  //     store.commit(mutationsType.SET_LOGIN_PRE_PATH, to.path);
  //     next(ROUTER_PATH.LOGIN);
  //   } else {
  //     next();
  //   }
  // }
});
new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount("#app");

Vue.filter(
  'cutString',function(val,s){
    let newstr = val;
    if (val && val.length > s) {
      newstr = val.substring(0,s) + "..."
    }

    return newstr;

  }
);

