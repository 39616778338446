<template>
  <div class="loading">
    <div>
      <img src="../../assets/images/loading.gif" />
      <span v-if="!!description">{{description}}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "LoadingMask",
  props: { description: String }
};
</script>
<style scoped>
.loading {
  position: fixed;
  z-index: 999;
  background-color: white;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.8;
  text-align: center;
}
.loading > div {
  position: relative;
  margin: 0 auto;
  top: 50%;
}
.loading > div > span {
  margin-left: 15px;
  font-size: 14px;
}
</style>